@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');

$primary-color: #4857c6;
$black-color: #222;
$lightgrey-color: #eee;
$red-color: #ec7878;

body{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: $black-color;
}

.popup-window {
  background-color: rgba(0,0,0,.3);
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.popup-window .popup {
  background-color: #fff;
  font-size: 18px;
  left: 0;
  margin: 10% auto 0;
  max-width: 320px;
  padding: 20px 25px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;
}

::-webkit-scrollbar-track{
	background-color: #fff;
}
::-webkit-scrollbar{
	width: 6px;
	background-color: #fff;
}
::-webkit-scrollbar-thumb{
	background-color: $primary-color;
}

img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
a{
  &:hover{
    text-decoration: none;
  }
}
ul{
  padding: 0;
  margin: 0;
  &.disc{
    padding-left: 20px;
  }
  &:not(.disc){
    list-style: none;
  }
}

h1,
h2,
h4,
h5{
  font-weight: 800;
  margin-bottom: 0;
}
h2{
  font-size: 3rem;
  text-shadow: 2px 2px 3px rgba(0,0,0,.15);
}
h3{
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}
h6{
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  &:last-of-type{
    margin-bottom: 0;
  }
}
p{
  margin-bottom: 1rem;
  &:last-of-type{
    margin-bottom: 0;
  }
}

@media (max-width: 991px){
  h2{
    font-size: 2.5rem;
  }
}

.overflow{
  overflow: hidden;
}
.animated-box-wrapper{
  padding: 12px;
  margin: -12px;
}

.mb-2rem{
  margin-bottom: 2rem;
}

.button{
  font-size: .925rem;
  display: table;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 34px;
  border-radius: 10px;
  border: 0;
  outline: 0 !important;
  box-shadow: 2px 2px 4px rgba(0,0,0,.15);
  transition: background-color .3s ease, color .3s ease;
  cursor: pointer;
  &-primary{
    background-color: $primary-color;
    color: #fff;
    &:hover{
      background-color: darken($primary-color, 15%);
      color: #fff;
    }
  }
  &-white{
    background-color: #fff;
    color: $primary-color;
    &:hover{
      background-color: $primary-color;
      color: #fff;
    }
  }
  &[disabled]{
    background-color: #ccc;
    pointer-events: none;
  }
}

.input-wrapper{
  position: relative;
  .form-input:not(select):not([type=file]){
    width: 100%;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #ccc;
    outline: 0;
    padding: 3px 0;
    margin-bottom: 16px;
    transition: border-color .2s ease;
    &#skypeOrTelegramName{
      padding-right: 104px;
    }
    &.error{
      border-color: $red-color;
    }
    &.focus{
      border-color: $primary-color;
    }
  }
  select{
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 3px 0;
  }
  textarea{
    height: 100px;
    min-height: 35px;
    max-height: 200px;
  }
  input[type=file]{
    outline: 0;
  }
  .form-label{
    position: absolute;
    top: 3px;
    left: 0;
    pointer-events: none;
    color: #999;
    margin-bottom: 0;
    transition: font-size .2s ease, top .2s ease;
    &.focus{
      font-size: .8rem;
      top: -12px;
    }
  }
  &.type-file{
    display: flex;
    border-bottom: 2px solid #ccc;
    transition: border-color .2s ease;
    &.error{
      border-color: $red-color;
    }
    .file-input-wrapper{
      min-width: 88px;
      background-color: $primary-color;
      color: #fff;
      padding: 8px 15px 0;
      height: 37px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: -2px;
      position: relative;
      overflow: hidden;
      transition: background-color .3s ease, color .3s ease;
      z-index: 1;
      &:hover{
        background-color: darken($primary-color, 15%);
      }
      input[type=file]{
        position: absolute;
        top: 0;
        right: 0;
        height: 35px;
        font-size: 20px;
        opacity: 0;
        cursor: pointer;
      }
    }
    .input-file-helper{
      width: calc(100% - 88px);
      padding: 3px 12px;
      border: none;
      background-color: transparent;
      outline: 0;
      pointer-events: none;
      &::placeholder{
        color: #999;
      }
    }
  }
}

/* /// header /// */

.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  transition: height .3s ease;
  z-index: 9;
  & div:not(.header-login-button-wrapper){
    height: 100%;
  }
  &-content{
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  &-logo{
    padding: 30px 0;
  }
  &-navigation{
    display: flex;
    align-items: center;
    ul{
      display: flex;
      align-items: center;
    }
    li{
      margin-right: 30px;
    }
    a:not(.button),
    button:not(.button){
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      padding: 0;
      color: #fff;
      font-weight: 700;
      position: relative;
      transition: color .3s ease;
      &::before{
        content: "";
        position: absolute;
        bottom: 24px;
        left: calc(50% - 1px);
        width: 3px;
        height: 50px;
        background-color: rgba(255,255,255,.9);
        transform-origin: top;
        transform: scaleY(0);
        transition: transform .25s ease;
      }
      &.active::before,
      &:hover::before{
        transform: scaleY(1);
      }
    }
    button:not(.button)::before{
      bottom: 26px;
    }
  }
  &-nav-wrapper{
    display: flex;
    align-items: center;
  }
  &.fixed{
    position: fixed;
    background-color: #fff;
    height: 80px;
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    animation: showHeader .95s forwards;
    a:not(.button),
    button:not(.button){
      font-size: .95rem;
      color: $black-color;
      &::before{
        visibility: hidden;
      }
      &.active,
      &:hover{
        color: $primary-color;
      }
    }
    .header-logo{
      padding: 15px 0;
    }
  }
  &.header-simple:not(.fixed){
    background-image: url('../img/bg-8.png');
    background-size: cover;
    background-position: center bottom;
  }
  .hamburger-wrapper{
    display: none;
    align-items: center;
    margin-left: 30px;
    .hamburger{
      width: 30px;
      height: 24px;
      cursor: pointer;
      position: relative;
      span{
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 2px rgba(0,0,0,.3);
        &:nth-last-of-type(1){
          top: 0;
        }
        &:nth-last-of-type(2){
          top: 10px;
        }
        &:nth-last-of-type(3){
          top: 20px;
        }
      }
    }
  }
}

@keyframes showHeader{
  0%{transform: translateY(-100%)}
  100%{transform: translateY(0)}
}

@media (max-width: 1199px){
  .header{
    .hamburger-wrapper{
      display: flex;
    }
    &-navigation{
      position: fixed;
      top: -100vh;
      left: 0;
      width: 100%;
      min-height: 100vh;
      background-image: linear-gradient(110deg, #106AB6, #9D3ADF);
      justify-content: center;
      transition: top 1s cubic-bezier(0.65, 0.05, 0.36, 1);
      z-index: 99;
      &.show{
        top: 0;
        .hide-nav{
          opacity: 1;
          transform: translateX(0);
        }
      }
      ul{
        flex-direction: column;
        li{
          margin: 0;
          a,
          button{
            color: #fff !important;
            font-size: 2.5rem !important;
            font-weight: 900 !important;
            text-transform: uppercase;
            &::before{
              display: none;
            }
            &::after{
              content: "";
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 100%;
              height: 5px;
              background-color: #fff;
              opacity: 0;
              transition: opacity .2s ease;
            }
            &.active::after,
            &:hover::after{
              opacity: 1;
            }
          }
        }
      }
      .hide-nav{
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        width: 38px;
        height: 38px;
        opacity: 0;
        transform: translateY(30px);
        transition: opacity .5s ease, transform .5s ease;
        transition-delay: 1s;
        cursor: pointer;
        &::before,
        &::after{
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 4px;
          background-color: #fff;
          border-radius: 5px;
        }
        &::before{
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after{
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
    &.fixed{
      .hamburger-wrapper span{
        background-color: $primary-color;
      }
    }
  }
}

@media (max-width: 575px){
  .header{
    .hamburger-wrapper{
      margin-left: 15px;
    }
    &-logo{
      display: flex;
      align-items: center;
      max-width: 46%;
    }
  }
}

/* /// opening section /// */

.opening-section{
  height: 900px;
  background-image: url('../img/bg-1.png');
  background-size: cover;
  background-position: center bottom;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  .container{
    position: relative;
  }
  &-content{
    margin-top: -100px;
    position: relative;
    z-index: 1;
    h1{
      color: #fff;
      font-size: 4rem;
    }
    p{
      margin-top: 30px;
      max-width: 600px;
      color: #fff;
      font-weight: 700;
    }
  }
  &-img{
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 25px));
    right: 15px;
    animation: animateOpeningImg 17s ease infinite;
    animation-delay: 2s;
    pointer-events: none;
  }
}
.scroll-down{
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 50px));
  left: 50px;
  display: flex;
  cursor: pointer;
  &-line{
    display: block;
    width: 3px;
    height: 102px;
    background-color: #fff;
    animation: scrollDownAnimation 2s ease infinite;
  }
  &-text{
    writing-mode: tb-rl;
    transform: rotate(180deg);
    font-weight: 700;
    color: #fff;
  }
}

.home-page .opening-section p{
  display: none;
}

@keyframes scrollDownAnimation{
  0%{
    transform-origin: top;
    transform: scaleY(0);
  }
  45%{
    transform-origin: top;
    transform: scaleY(1);
  }
  55%{
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100%{
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

@keyframes animateOpeningImg{
  0%{transform: translateY(calc(-50% + 25px))}
  50%{transform: translateY(calc(-50% - 50px))}
  100%{transform: translateY(calc(-50% + 25px))}
}

@media (max-width: 1799px){
  .opening-section{
    &-img{
      right: -50px;
      width: 58%;
    }
    p{
      max-width: 500px;
    }
  }
}

@media (max-width: 1299px){
  .scroll-down{
    display: none;
  }
}

@media (max-width: 1199px){
  .opening-section-img{
    right: -100px;
  }
}

@media (max-width: 991px){
  .opening-section{
    background-image: none;
    background-color: lighten($primary-color, 15%);
    height: auto;
    padding: 80px 0;
    .container{
      display: flex;
      flex-direction: column;
    }
    &-content{
      order: 2;
      h1{
        text-align: center;
        font-size: 3rem;
      }
      p{
        text-align: center;
        max-width: none;
      }
    }
    &-img{
      top: 0;
      left: 15%;
      right: initial;
      width: 70%;
      margin-top: calc(50% - 100px);
      position: relative;
    }
  }
}

@media (max-width: 767px){
  .opening-section-img{
    left: 10%;
    width: 80%;
    margin-top: calc(50% - 50px);
  }
}

@media (max-width: 575px){
  .opening-section-img{
    left: 0;
    width: 100%;
    margin-top: 50%;
  }
  .opening-section h1{
    font-size: 2.5rem;
  }
}

/* /// how we works section /// */

.how-we-works,
.about-second-section,
.career-2{
  padding: 20px 0 50px;
  &-content{
    max-width: 800px;
    margin: 0 auto;
    h6,
    p{
      text-align: center;
    }
  }
}

@media (max-width: 991px){
  .how-we-works,
  .about-second-section,
  .career-2{
    padding: 80px 0;
  }
}

/* /// products section /// */

.products{
  background-image: url('../img/bg-2.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 260px 0 340px;
  &-content{
    .content-box{
      background-color: rgba(255,255,255,.6);
      border-radius: 20px;
      padding: 30px;
      margin-top: 30px;
      position: relative;
      overflow: hidden;
      &::before{
        content: "";
        position: absolute;
        right: -20px;
        bottom: -20px;
        width: 200px;
        height: 200px;
        background-size: cover;
        background-position: center center;
        opacity: .15;
        pointer-events: none;
        transform: rotate(4deg);
      }
      h4{
        color: $primary-color;
        margin-bottom: 20px;
      }
    }
    .col-lg-4:nth-of-type(1) .content-box::before{
      background-image: url('../img/svg-icons/video.svg');
    }
    .col-lg-4:nth-of-type(2) .content-box::before{
      background-image: url('../img/svg-icons/sport.svg');
    }
    .col-lg-4:nth-of-type(3) .content-box::before{
      background-image: url('../img/svg-icons/giftbox.svg');
    }
    .col-lg-4:nth-of-type(4) .content-box::before{
      background-image: url('../img/svg-icons/file.svg');
    }
    .col-lg-4:nth-of-type(5) .content-box::before{
      background-image: url('../img/svg-icons/secure-shield.svg');
    }
    .col-lg-4:nth-of-type(6) .content-box::before{
      background-image: url('../img/svg-icons/barbell.svg');
    }
  }
  h2{
    color: #fff;
    margin-bottom: 20px;
  }
}

@media (max-width: 1799px){
  .products{
    background-size: cover;
  }
}

@media (max-width: 991px){
  .products{
    background-image: none;
    background-color: lighten($primary-color, 15%);
    padding: 80px 0;
    h2{
      text-align: center;
    }
  }
}

/* /// publishers section /// */

.publishers{
  padding: 250px 0 50px;
  margin-top: -100px;
  .img-wrapper{
    margin-top: -100px;
  }
  &-content{
    h2{
      color: $primary-color;
      margin-bottom: 50px;
    }
    .button-wrapper{
      margin: 50px 0 80px;
    }
  }
}

@media (max-width: 1199px){
  .publishers{
    margin-top: 0;
    padding-top: 50px;
    .img-wrapper{
      margin-top: 0;
    }
    &-content .button-wrapper{
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1199px){
  .publishers{
    padding: 80px 0;
    .img-wrapper{
      padding: 0 15%;
      margin-bottom: 80px;
    }
  }
}

@media (max-width: 575px){
  .publishers .img-wrapper{
    padding: 0;
    width: 100%;
  }
}

/* /// publishers_2 section /// */

.publishers_2{
  background-image: url('../img/bg-3.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 350px 0;
  &-content{
    h6{
      text-align: center;
      margin-bottom: 20px;
    }
    .content-box{
      text-align: center;
      background-color: $primary-color;
      color: #fff;
      border-radius: 20px;
      padding: 30px;
      margin-top: 30px;
      .icon{
        max-width: 90px;
        margin: 0 auto 24px;
      }
      h4{
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1799px){
  .publishers_2{
    background-size: cover;
  }
}

@media (max-width: 991px){
  .publishers_2{
    background-image: none;
    background-color: lighten($primary-color, 40%);
    padding: 80px 0;
  }
}

/* /// contact section /// */

.contact{
  padding: 150px 0 20px;
  margin-top: -100px;
  &-content{
    max-width: 700px;
    margin: 0 auto;
    h2{
      color: $primary-color;
      text-align: center;
      margin-bottom: 30px;
    }
    p{
      text-align: center;
      margin-bottom: 50px;
    }
    .home-page-form-wrapper{
      max-width: 550px;
      margin: 0 auto;
    }
    .captcha-wrapper{
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
    .submit-wrapper{
      .button{
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 991px){
  .contact{
    margin-top: 0;
    padding: 80px 0;
  }
}

/* /// footer section /// */

.footer{
  background-image: url('../img/bg-4.png');
  background-size: cover;
  background-position: center top;
  text-align: center;
  padding: 230px 0 50px;
  color: #fff;
  &-content{
    .social{
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      li:not(:last-of-type){
        margin-right: 15px;
      }
      .icon{
        max-width: 24px;
        img{
          opacity: .65;
          transition: opacity .2s ease;
        }
      }
      a:hover img{
        opacity: 1;
      }
    }
    .legal{
      margin-bottom: 20px;
      a{
        color: #fff;
        opacity: .65;
        transition: opacity .2s ease;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 991px){
  .footer{
    background-image: none;
    background-color: $primary-color;
    padding: 50px 0;
  }
}

/* /// about page /// */

.about-counters{
  background-image: url('../img/bg-5.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 70px 0 80px;
  &-content{
    .content-box{
      text-align: center;
      span{
        display: block;
        color: $primary-color;
        font-size: 4rem;
        line-height: 5rem;
        font-weight: 800;
      }
    }
  }
}

.about-bg-wrapper{
  background-image: url('../img/bg-6.png');
  background-size: cover;
  background-position: center top;
}

.about-2{
  padding: 50px 0 60px;
  &-content{
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
}

.about-3{
  padding: 20px 0 130px;
  &-content{
    .content-box{
      text-align: center;
      background-color: $primary-color;
      color: #fff;
      border-radius: 20px;
      padding: 30px 60px;
      margin-top: 30px;
      .icon{
        max-width: 90px;
        margin: 0 auto 24px;
      }
    }
  }
}

.about-4{
  &-content{
    background-image: url('../img/bg-7.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 130px 0;
    text-align: center;
    .img-wrapper{
      display: inline-block;
      margin-bottom: 42px;
    }
    .text-wrapper{
      max-width: 800px;
      margin: 0 auto;
    }
    .button-wrapper{
      margin-top: 50px;
      .button{
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 991px){
  .about-counters{
    background-image: none;
    padding: 0;
  }
  .about-bg-wrapper{
    background-image: none;
  }
  .about-2{
    padding: 80px 0;
  }
  .about-3{
    padding: 0;
  }
  .about-4{
    padding: 80px 0;
    &-content{
      padding: 0;
      background-image: none;
    }
  }
}

/* /// career page /// */

.career-3{
  &-content{
    margin-top: -20px;
    h2{
      margin-bottom: 10px;
      text-align: center;
    }
    h4{
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 40px;
    }
    .career-list{
      margin-bottom: 50px;
    }
    .career-item{
      min-height: 183px;
      border-radius: 10px;
      padding: 30px;
      margin-top: 30px;
      position: relative;
      box-shadow: 3px 3px 12px rgba(0,0,0,.3);
      h3{
        margin-bottom: 1rem;
      }
      p{
        max-width: calc(100% - 210px);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .button{
        position: absolute;
        right: 30px;
        bottom: 30px;
      }
    }
  }
}

@media (max-width: 767px){
  .career-3{
    &-content{
      .career-item{
        p{
          max-width: none;
          margin-bottom: 1rem;
        }
        .button{
          position: relative;
          right: initial;
          bottom: initial;
        }
      }
    }
    h4{
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
}

/* /// vacancy page /// */

.vacancy-page{
  .opening-section{
    &-content{
      text-align: center;
      p{
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-img{
      display: none;
    }
  }
  .vacancy-2{
    &-content{
      padding: 100px 0 calc(80px - 3rem);
      p{
        margin-bottom: 1rem;
      }
      ul{
        margin-bottom: 3rem;
      }
    }
  }
}

.vacancy-form{
  &-content{
    border-radius: 10px;
    padding: 35px 30px;
    max-width: 610px;
    box-shadow: 3px 3px 12px rgba(0,0,0,.3);
    margin: 0 auto 50px;
    h3{
      color: $primary-color;
      text-align: center;
      margin-bottom: 2rem;
    }
    .submit-wrapper{
      margin-top: 30px;
      .button{
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 991px){
  .vacancy-page{
    .opening-section-content{
      margin-top: 50px;
    }
    .vacancy-2-content{
      padding-top: 80px;
    }
  }
}

@media (max-width: 575px){
  .vacancy-form-content{
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }
}

/* /// login page /// */

.login-page{
  background-image: linear-gradient(110deg, #106AB6, #9D3ADF);
  min-height: 100vh;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form-box{
    background-color: #fff;
    padding: 50px;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 3px 3px 12px rgba(0,0,0,.3);
    h3{
      text-align: center;
      text-transform: uppercase;
      font-size: 1.75rem;
      color: $primary-color;
      margin-bottom: 40px;
    }
    .form-input{
      margin-bottom: 20px !important;
    }
    .submit-wrapper{
      margin: 1rem 0 1.5rem;
      button{
        width: 100%;
        border-radius: 50px;
      }
    }
    p{
      text-align: center;
    }
    a{
      color: $primary-color;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 575px){
  .login-page{
    .login-form-box{
      padding: 50px 30px;
    }
  }
}

/* /// legal pages /// */

.legal-page{
  margin: 170px 0 30px;
  h3{
    text-align: center;
    color: $primary-color;
    text-transform: uppercase;
    font-size: 2rem;
    text-shadow: 1px 1px 3px rgba(0,0,0,.15);
    margin-bottom: 30px;
  }
}